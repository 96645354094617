import { useState,  useEffect, useRef  } from "react";
import { SearchBar } from "../../components";
import QueryAns from "../../components/queryAns/QueryAns";
import "./chat.css";
import search from "../../assets/images/search.png";
import { useNavigate } from "react-router-dom";
import defaultProfileIcon from "../../assets/images/defaultProfileIcon.png"; // Default icon image
import axios, { AxiosError } from "axios";

interface ChatProps {
  handleLogout: () => void;
}

const Chat: React.FC<ChatProps> = ({ handleLogout }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  // const [profilePicUrl, setProfilePicUrl] = useState(""); // State for the profile picture URL
  const [userName, setUserName] = useState("Guest"); // State for the user name
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false); // Help modal state
  const [selectedFiles, setSelectedFiles] = useState([]); // State for selected files
  // const [files, setFiles] = useState<FileList | null>(null); // State to hold selected files
  const [isUploadFormOpen, setIsUploadFormOpen] = useState<boolean>(false);; // State to manage upload form visibility
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[]>([]); // Explicitly define as File[]
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false); // State for profile modal
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');


  const [messages, setMessages] = useState<string[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [userId, setUserId] = useState<number>(1); // Set a default user ID or fetch it dynamically
  const [email, setEmail] = useState<string>('');
  const userEmail = localStorage.getItem('userEmail') || '';
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [role, setRole] = useState('');
  const [showHamburger, setShowHamburger] = useState<boolean>(true);
  const [color, setColor] = useState<string>("white");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);



  const [userProfile, setUserProfile] = useState({
    first_name: '',
    last_name: '',
    email: '' ,
    role: ''
  });


  // Toggle Profile Modal
  const toggleProfileModal = () => {
    setIsProfileModalOpen(prevState => !prevState);
  };

  interface ErrorResponse {
    error: string;
    // Add any other properties you expect from the error response
}




  // Define the ErrorResponse interface here
  interface ErrorResponse {
    error: string;
    message?: string; // Optional, depending on your API
  }

  const toggleSidebar = () => {
    // setIsOpen(!isOpen);
    // document.getElementById('sidebar')?.classList.toggle('open');
    // document.getElementById('chat-container')?.classList.toggle('sidebar-open');
    setIsOpen(prevState => !prevState); // Toggle the state

  }
  const toggleHelpDialog = () => {
    setIsHelpOpen(prevState => !prevState); // Toggle help dialog
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  const handleUploadClick = () => {
    // Check if the file input ref exists and is not null
    if (fileInputRef.current) {
      fileInputRef.current?.click();      // Trigger the file input click
    } else {
      console.error("File input element is not available.");
    }
  };

  // const handleFileUpload = async () => {
  //   const formData = new FormData();
  //   if (files.length > 0) {
  //     formData.append('file', files[0]);

  const handleFileUpload = async () => {
    const formData = new FormData();
    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append('files', file); // Append all files
      });

      try {
        console.log("formData...", formData)
        const response = await axios.post('https://dms1.geotechnosoft.com/upload_process_doc', formData, {

          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert(response.data.message);
        setFiles([]); // Clear the files after successful upload
      } catch (error) {
        const axiosError = error as AxiosError; // Type assertion
        if (axiosError.response){
          const errorData = axiosError.response.data as ErrorResponse; // Type assertion for error data
          alert('Error: ' + errorData.error); // Use the typed error data

        }
        // const axiosError = error as AxiosError;

        // if (axiosError.response) {
        //     console.log(axiosError.response.data); // Log the response data
        //     const errorData = axiosError.response.data as ErrorResponse;
        //     alert('Error uploading file: ' + errorData.error);
        // } else {
        //     alert('Error uploading file: ' + axiosError.message);
        // }
      }
    } else {
      alert('No file selected');
    }
  };

  const toggleUploadForm = () => {
    setIsUploadFormOpen(!isUploadFormOpen);

    // setIsUploadFormOpen(prevState => !prevState); // Toggle upload form
    if (isUploadFormOpen) {
      setFiles([]); // Clear the files when closing the form
    }
  };



  const handleBackClick = () => {
    navigate('/');
  };



  // Function to handle category selection
  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // Fetch user data from local storage
    const storedEmail = localStorage.getItem('email');
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');
    const storedUserName = localStorage.getItem('userName');
    const storedRole = localStorage.getItem('userRole');

    if (storedEmail) setEmail(storedEmail);
    if (storedFirstName) setFirstName(storedFirstName);
    if (storedLastName) setLastName(storedLastName);
    if (storedUserName) setUserName(storedUserName);
    if (storedRole) setRole(storedRole);

    // Determine hamburger visibility based on role
    if (storedRole === 'admin') {
      setShowHamburger(true);
    } else {
      setShowHamburger(false);
    }



    // If we don't have the user data in local storage, fetch it from the server
    if (!storedFirstName || !storedLastName || !storedRole ) {
      fetchUserData(storedEmail);
    }
  }, []);

  const fetchUserData = async (email: string | null) => {
    if (!email) return;
    try {
      const response = await axios.get(`https://dms1.geotechnosoft.com/get_user`, {
        params: { email }
      });
      const userData = response.data;
      if (userData.error) {
        throw new Error(userData.error);
      }
      setFirstName(userData.first_Name);
      setLastName(userData.last_Name);
      setUserName(`${userData.first_Name} ${userData.last_Name}`);
      setRole(userData.role);

      // Update local storage
      localStorage.setItem("firstName", userData.first_Name);
      localStorage.setItem("lastName", userData.last_Name);
      localStorage.setItem("userName", `${userData.first_Name} ${userData.last_Name}`);
      localStorage.setItem("userRole", userData.role);
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };



  const handleSaveChanges = async () => {
    try {
      const updatedUserData = { email, firstName, lastName, role };
      const response = await axios.post('https://dms1.geotechnosoft.com/update_user', updatedUserData);
      console.log('User profile updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  



  // Function to trigger the file processing
  const handleProcessClick = async () => {
    setIsProcessing(true);  // Set processing to true while the request is being made
    try {
      const response = await fetch('https://dms1.geotechnosoft.com/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();
      if (response.ok) {
        alert('All files processed successfully');
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      alert(`Failed to process files:`);
    } finally {
      setIsProcessing(false);  // Reset the processing state
    }
  };


  // Function to trigger the file processing
  const IndexProcessClick = async () => {
    setIsProcessing(true);  // Set processing to true while the request is being made
    try {
      const response = await fetch('https://dms1.geotechnosoft.com/create_faiss_index', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();
      if (response.ok) {
        alert('Index created successfull !');
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      alert(`Failed to create index:`);
    } finally {
      setIsProcessing(false);  // Reset the processing state
    }
  };

  //To process new Docs
    const handleProcessDocs = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.post('https://dms1.geotechnosoft.com/index_new_docs');
        alert(response.data.message);  // Show the returned message in an alert
      } catch (error) {
        console.error('Error processing documents:', error);
        alert('An error occurred while processing documents.');
      }finally {
        setLoading(false); // Stop loading
      }
    };

   

  return (
    <div className={`chat-container`}>
      {/* Top Bar */}
      <div className="top-bar">
        <div className="top-bar-left">
          {role === 'admin' && (
            <div className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
          <div className={`sidebar ${isOpen ? 'open' : ''}`} id="sidebar">
            {/* Sidebar content */}
            <ul>
            
            <li>
              <button className="sidebar-btn" onClick={toggleUploadForm}>Upload</button>
              {isUploadFormOpen && (
                <div className="upload-form-overlay">
                  <div className="upload-form-container">
                    <button className="form-close-btn" onClick={toggleUploadForm}>X</button>
                    <div className="header">
                      <p>Browse Files to upload!</p>
                    </div>
                    <input
                      id="file-input"
                      type="file"
                      ref={fileInputRef}
                      accept="application/pdf, .pdf" 
                      style={{ display: "none" }}
                      multiple
                      onChange={handleFileChange}
                    />
                    <label className="footer" onClick={handleUploadClick}>
                      <p>{files.length > 0 ? files[0].name : "No file selected"}</p>
                    </label>
                    <button className="upload-btn" onClick={handleFileUpload}>Upload</button>
                  </div>
                </div>
              )}
            </li>
            <li>
            <button className="sidebar-btn" onClick={handleProcessDocs} disabled={loading}>
    {loading ? 'Processing...' : 'Process New Docs'}
  </button>            </li>
            <li>
              <button className="sidebar-btn" onClick={toggleProfileModal}>My Profile</button>
            </li>
          </ul>
          </div>
          <img className="chatbot-header-img" src={search} alt="Logo" />
          <h3 className="chatbot-header-txt">DocAI</h3>
        </div>
        <div className="top-bar-right">
          <div className="color-options" style={{ display: 'flex', alignItems: 'center' }}>
            <button onClick={() => setColor("#255570")} style={{ backgroundColor: "#255570" }}></button>
            <button onClick={() => setColor("#1A3636")} style={{ backgroundColor: "#1A3636" }}></button>
            <button onClick={() => setColor("#36BA98")} style={{ backgroundColor: "#36BA98" }}></button>
            <button onClick={() => setColor("#000000")} style={{ backgroundColor: "#000000" }}></button>
          </div>
          <div className="user-profile-container">
            <img
              className="profile-pic"
              src={defaultProfileIcon} // If profilePicUrl is empty, use default icon
              alt="User Profile"
            />
            <span className="user-name">{userName}</span>
            <div className="dropdown">
              <button className="dropdown-btn">▼</button>
              <div className="dropdown-content">
                {/* <a href="#" id="help-btn" onClick={toggleHelpDialog}>Help</a> */}
                <a href="#" onClick={handleLogout}>Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chatbot Conversation */}
      <div className="chat-conversation" style={{ backgroundColor: color }}>
        <div className="chatbot-header">
          {/* <div className="color-options">
            <button onClick={() => setColor("#255570")}></button>
            <button onClick={() => setColor("#1A3636")}></button>
            <button onClick={() => setColor("#36BA98")}></button>
            <button onClick={() => setColor("#000000")}></button>
          </div> */}
          <div className="row">
            <button
              className="Process-btn"
              onClick={handleProcessClick}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Process Files"}
            </button>
            <button
              className="FAISS-btn"
              onClick={IndexProcessClick}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Create Index"}
            </button>
          </div>
        </div>
        <QueryAns id="queryAns-main-container" />
        <div className="chatbot-footer">
          <SearchBar
            id={"searchbar"}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
           <button className="hamburger-btn" onClick={toggleMenu} style={{ marginLeft: '10px' }}>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
          </button>
        </div>
      </div>

      {/* Bottom to top dropdown menu */}
      {isMenuOpen && (
        <div className="dropdown-menu">
          <ul>
            <li><a href="https://brlps.in/" target="_blank" rel="noopener noreferrer">Brlps</a></li>
            <li><a href="https://mis.brlps.in/bireport/bireports.aspx" target="_blank" rel="noopener noreferrer">CEO Dashboard</a></li>
            <li><a href="https://app.powerbi.com/view?r=eyJrIjoiMjAwYjU5MjUtZDk0Mi00NzM0LWIzNzgtZjlhNDk1ZjkxMjI5IiwidCI6ImE3NDRlNmIxLTI1ODAtNDk5MS04YjMyLTY1MWY3ZTJkZWY0NiJ9" target="_blank" rel="noopener noreferrer">CLF 360</a></li>
          </ul>
        </div>
      )}

    

      

  {/* Profile Modal */}
  {isProfileModalOpen && (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Edit Profile</h2>
        <form>
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              readOnly
              autoComplete="given-name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              readOnly
              autoComplete="family-name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              readOnly
              autoComplete="email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role:</label>
            <input
              type="text"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              readOnly
              autoComplete="role"
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="save-btn" onClick={handleSaveChanges}>
              Save
            </button>
            <button type="button" className="cancel-btn" onClick={toggleProfileModal}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )}
</div>
  )
}

export default Chat;