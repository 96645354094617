// src/utils/handleSpeechSynthesis.js
let isPaused = false; // Track if the speech is paused
let utterance = null; // Track the current utterance

function handleSpeechSynthesis(text, language = 'en-US') {
    const synth = window.speechSynthesis;
    let voices = synth.getVoices();

    // Stop any ongoing or paused speech and reset the state
    if (synth.speaking || isPaused) {
        synth.cancel(); // Stop the current speech
        isPaused = false;
        utterance = null;
        return;
    }

    const performSpeak = () => {
        // Create a new utterance for the new paragraph
        utterance = new SpeechSynthesisUtterance(text);

        // Find a suitable female voice for the specified language
        const suitableVoice = voices.find(voice => (
            voice.lang.startsWith(language) &&
            (voice.name.includes('Female') || voice.name.includes('female') || voice.gender === 'female')
        ));
  
        utterance.voice = suitableVoice || voices[0]; // Fallback to the first available voice
        utterance.pitch = 1;
        utterance.rate = 1;

        // Reset the state when speaking ends
        utterance.onend = () => {
            isPaused = false;
            utterance = null;
        };

        synth.speak(utterance); // Start speaking the new paragraph
    };

    // If voices are already loaded, perform speech
    if (voices.length > 0) {
        performSpeak();
    } else {
        // Load voices if not available yet and then speak
        synth.onvoiceschanged = () => {
            voices = synth.getVoices();
            performSpeak();
        };
    }
}

export default handleSpeechSynthesis;
